<template>
  <div class="container">
    <br>
    <div class="page-header">
      <div class="row">
        <div class="col-12 col-md-9">
          <h1>
            {{ $t('TEAMS.title') }}
          </h1>
        </div>
        <div class="col-12 col-md-3">
          <div class="page-actions">
            <b-btn
              block
              v-b-modal.modalCreate
              v-b-tooltip.hover
              :title="$t('TEAMS.create_team')"
              v-if="isLoggedIn && currentUser.role && currentUser.role == 'role_user_admin'"
            >
              <i
                class="fas fa-folder-plus"
              ></i>
              {{ $t('TEAMS.create_team') }}
            </b-btn>
          </div>

        </div>
      </div>
      
    </div>

    <b-modal id="modalCreate" ref="modalCreate" :title="$t('TEAMS.modal.title')" :hide-footer="true">
      <form @submit.stop.prevent="handleCreateSubmit">
        <b-form-input type="text" :placeholder="$t('TEAMS.modal.name_placeholder')" v-model="team.name"></b-form-input>
        <b-btn type="submit" variant="primary">{{ $t('TEAMS.modal.submit') }}</b-btn>
      </form>
    </b-modal>

    <div class="stats-table table-responsive">
      <table class="table is-hoverable">
        <tbody>
          <tr v-for="team in teams" :key="team.id">
            <td>
              <div class="team-single">
                <router-link
                  :to="{ name: 'TeamSingle', params: { id: team.to_param }}"
                  class=""
                >
                  <div class="avatar" v-if="team.avatar && team.avatar.url">
                    <img :src="team.avatar.url">
                  </div>
                  <div class="content">
                    <h4 v-html="team.name || $t('UTILITY.unnamed') "></h4>
                    <p>{{ team.matches_count }} {{ $t('UTILITY.matches') }}</p>
                  </div>
                </router-link>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// import Header from '@/components/Header'
export default {
  name: "TeamList",
  data() {
    return {
      teams: [],
      team: { name: "" }
    };
  },
  computed: {
  },
  methods: {
    initialize() {
      this.$http
        .get("teams.json")
        .then(response => (this.teams = response.data));
    },

    handleCreateSubmit() {
      this.$http.post(`teams.json`, this.team).then(response => {
        this.initialize();
        this.$refs.modalCreate.hide();
      });
    }
  },
  mounted() {
    this.initialize();
  },
  components: {}
};
// Header
</script>

<style lang="sass" scoped>
a
  color: #111
  transition: all .3s
  &:hover
    text-decoration: none
    color: $link
.team-single
  a
    display: flex
    align-items: center
    .avatar
      margin-right: 10px
      img
        width: 48px
        height: 48px
    .content  
      h4
        font-weight: bold
        margin: 0
      p
        margin: 0
</style>
